<template>
  <div>
    <a-table
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :scroll="{ x: 800 }"
      :rowKey="(record) => record.id"
      :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    >
      <template slot="price">
        <div style="display: flex; align-items: center">
          <div style="width: 50px">成交价</div>
        </div>
      </template>
      <div
        slot="size-weight"
        slot-scope="row"
      >
        {{ row.goods && row.goodsExtraInfo.size }}/{{ row.goodsExtraInfo && row.goodsExtraInfo.weight }}
      </div>
      <template slot="time">
        <div style="display: flex; align-items: center">
          <div style="width: 80px">成交时间</div>
        </div>
      </template>
      <div
        slot="coverUrl"
        slot-scope="row"
      >
        <img
          @click="previewImg(row)"
          style="width: 50px; height: 50px"
          :src="row.coverUrl ? row.coverUrl : 'http://image.douquan.com/static/product-default.png'"
          alt=""
        />
      </div>
<!--   确认，对版状态   -->
      <div slot="reviewStatusOrDuiBanStatusSlot" slot-scope="row">
        <div v-if="row.reviewStatus">
          <div v-if="row.reviewStatus === 0" class="color-red">未确认</div>
          <div v-if="row.reviewStatus === 1" class="color-green">已确认</div>
        </div>
        <div class="mt-10" v-if="row.duibanStatus">
          <div v-if="row.duibanStatus === 0" class="color-orange">未对版</div>
          <div v-if="row.duibanStatus === 1" class="color-blue">已对版</div>
          <div v-if="row.duibanStatus === 5" class="color-blue">已对版-不准</div>
          <div v-if="row.duibanStatus === 9" class="color-blue">已对版-无结果</div>
        </div>
        <div
            class="mt-10 color-black"
            :class="row.duibanId ? 'color-orange cur-pot' : ''"
            v-if="row.versionName"
            @click="handleGoToVersionList(row)"
        >{{ row.versionName }}</div>
        <div class="mt-10">
          <a-button v-if="row.duibanStatus" size="small" type="primary" @click="handleSameVersion(row)">同版</a-button>
          <a-button :class="row.duibanStatus ? 'ml-10' : ''" size="small" type="primary" @click="handleGoToBootVersion(row)">机器对版</a-button>
        </div>
      </div>
<!--   操作   -->
      <div slot="setup" slot-scope="row" style="display: flex; flex-direction: column">
        <div style="margin-top: 5px; display: flex">
          <a-popconfirm
              title="是否确认?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleConfirmPriceQuery(row.id)"
          >
            <a-button size="small" type="primary" style="margin-right: 5px">确认</a-button>
          </a-popconfirm>
          <a-button
            size="small"
            @click="editItem(row)"
            style="margin-right: 5px"
          >编辑</a-button>
          <a-button
            v-if="false"
            size="small"
            @click="appreciation(row)"
            style="margin-right: 5px"
          >鉴赏</a-button>
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row.id)"
          >
            <a-button
              size="small"
              type="danger"
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
    <AddAppreciation
      ref="add-appreciation"
      :detail="detail"
      @success="appreciationSuccess"
    ></AddAppreciation>
<!--  对版弹窗   -->
    <ImgVersionPopup ref="imgVersionPopupEl" @versionSuccess="appreciationSuccess"/>
  </div>
</template>

<script>
import AddAppreciation from "@/views/cmsPage/coinManage/goods/appreciation.vue";
import {queryPriceBatchConfirm, queryPriceBatchDel} from "@/views/cmsPage/coinManage/_apis"
import ImgVersionPopup from "@/views/cmsPage/coinManage/goods/_components/ImgVersionPopup/index.vue"

export default {
  props: ["list", "loading", "pagination"],
  components: {
    AddAppreciation,
    ImgVersionPopup,
  },
  data() {
    return {
      detail: "",
      columns: [
        { title: "id", dataIndex: "id", width: 100, align: 'center', },
        { title: "编号", dataIndex: "pcgsNumber", width: 120,align: 'center', },
        {
          title: "组合标题",
          align: 'center',
          width: 240,
          fiexd: "left",
          customRender: (text, row, index) => {
            return <span style="color:#1890ff">{row.title}</span>;
          },
        },
        {
          title: "封面图",
          align: 'center',
          width: 80,
          scopedSlots: { customRender: "coverUrl" },
        },
        {
          title: "数据源",
          align: 'center',
          width: 80,
          customRender: (text, row, index) => {
            switch (row.goodsSource) {
              case 1:
                return "HX";
              case 2:
                return "QBTT";
              case 3:
                return "WTP";
              case 4:
                return "XZ";
              case 5:
                return "GQYD";
              case 25:
                return "HS";
              default:
                return "-";
            }
          },
        },
        {
          key: "time",
          width: 120,
          align: 'center',
          scopedSlots: { customRender: "time", title: "time" },
          customRender: (text, row, index) => {
            return <span style="color: green">{row.time}</span>;
          },
        },
        {
          title: "评级公司",
          width: 120,
          align: 'center',
          customRender: (text, row, index) => {
            switch (row.goodsType) {
              case 1:
                return "PCGS";
              case 2:
                return "公博";
              case 10:
                return "保粹";
              case 11:
                return "华夏";
              case 12:
                return "众诚详评";
              case 13:
                return "至臻";
              case 20:
                return "裸币";
              default:
                return "-";
            }
          },
        },
        {
          key: "action",
          align: 'center',
          width: 100,
          scopedSlots: { customRender: "action", title: "price" },
          customRender: (text, row, index) => {
            return <span style="color:red">{row.price}</span>;
          },
        },
        {
          title: "尺寸/重量",
          scopedSlots: { customRender: "size-weight" },
          align: 'center',
          width: 160,
        },
        { title: "确认状态/对版状态", scopedSlots: {customRender: "reviewStatusOrDuiBanStatusSlot"}, width: 200, align: 'center', },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
          width: 200,
        },
      ],
      selectedRowKeys: [],
    };
  },
  watch: {
    list() {
      this.selectedRowKeys = [];
      this.$emit("selectTable", []);
    },
  },
  methods: {
    /** 去往机器对版 */
    handleGoToBootVersion(row) {
      this.$refs.imgVersionPopupEl.show(row)
    },
    /** 去往对版列表 */
    handleGoToVersionList(row) {
      if (!row.duibanId) return
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?recordId=${row.duibanId}`;
      window.open(url)
    },
    /** 同版 */
    handleSameVersion(row) {
      const versionData = {
        coinKindId: row.coinKindId, // 大币种ID
        coinKindSid: row.coinKindSid, // 大币种sid
        coinKindName: row.coinName, // 大币种名称
        coinKindItemId: row.coinKindItemId, // 小币种ID
        coinKindItemVersionId: row.coinKindItemVersionId // 版别ID
      }
      this.$emit('sameVersion', versionData)
    },
    /** table选中 */
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.$emit("selectTable", arr);
    },
    /** 确定确认 */
    async handleConfirmPriceQuery(id) {
      const res = await queryPriceBatchConfirm({ids: id})
      if (res.status !== '200') return
      this.$message.success(res.message || "确认成功");
      this.$emit("success");
    },
    appreciationSuccess() {
      this.$emit("success");
    },
    // 鉴赏
    appreciation(row) {
      this.detail = JSON.parse(JSON.stringify(row));
      this.$refs["add-appreciation"].show = true;
    },
    // 编辑
    editItem(item) {
      this.$emit("editItem", item);
    },
    // 删除
    async remove(id) {
      this.$loading.show();
      const res = await queryPriceBatchDel({ids: id})
      this.$loading.hide();
      if (res.status != '200') return;
      this.$message.success(res.message || "删除成功");
      this.$emit("success");
    },

    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
    // 预览封面
    previewImg(row) {
      const images = row.images.map((el) => {
        return {
          img_url: el,
        };
      });
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  max-width: 600px;
}
</style>