
/** 前台搜索排序 0默认  1:价格正序   2:价格倒序  3：时间正序  4：时间倒序*/
export const RECEPTION_SORT_TYPE_LIST = [
    {name: '时间降序', value: 4},
    {name: '价格升序', value: 1},
    {name: '价格降序', value: 2},
]

/** goods v2列表 */
export const GOODS_PAGE_TABLE_LIST = [
    { title: "id", dataIndex: "goodsId", width: 100, align: 'center', },
    { title: "名称", scopedSlots: { customRender: 'itemGoodsNameSlot'}, width: 300, align: 'center', },
    { title: "图片", scopedSlots: { customRender: 'itemImageSlot' }, width: 600, align: 'center', },
    { title: "其他信息", scopedSlots: { customRender: 'itemOtherInfoSlot' }, width: 200, align: 'center', },
    { title: "操作", scopedSlots: { customRender: "itemAuctionSlot" }, width: 200, align: 'center'},
]