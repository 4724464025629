<template>
  <div>
    <a-modal
      v-model="show"
      title="鉴赏"
      ok-text="确认"
      cancel-text="取消"
      @ok="submit"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        v-bind="layout"
      >
        <a-form-model-item label="ID">
          <a-input
            disabled
            size="small"
            v-model="forms.id"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="标题">
          <a-input
            disabled
            size="small"
            v-model="forms.title"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="编号">
          <a-input
            disabled
            size="small"
            v-model="forms.pcgsNumber"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item label="赏析">
          <a-input
            size="small"
            type="textarea"
            placeholder="赏析内容"
            autoSize
            v-model="forms.appreciationContext"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["detail"],
  data() {
    return {
      show: false,
      forms: {},
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
    };
  },
  watch: {
    detail: {
      deep: true,
      handler() {
        this.forms = JSON.parse(JSON.stringify(this.detail));
      },
    },
  },
  methods: {
    async submit() {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverGuqianbiGoodsAppreciate/addAppreciate",
        {
          params: {
            goodsId: this.forms.id,
            appreciationContext: this.forms.appreciationContext,
          },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.show = false;
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>