<template>
  <div>
    <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :scroll="{ x: 800 }"
        :rowKey="(record) => record.id"
        :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    >
<!--   图片   -->
      <div slot="itemImageSlot" slot-scope="row">
        <div class="flex-start-center flex-wrap">
          <img
              v-for="(item, index) in row.goodsCovePrimaryList"
              :key="index"
              :src="item + '/resize,w_250'"
              class="w-150"
              @click="previewImg(row)"
          >
        </div>
      </div>
<!--   标题   -->
      <div slot="itemGoodsNameSlot" slot-scope="row">
        <div v-if="!row.showDoubleInput" @dblclick="handleDoubleClick(row)">{{ row.goodsName }}</div>
        <div v-else class="w-100-w flex-center-center">
        <a-textarea
            type="text"
            class="w-100-w"
            v-model="goodsName"
            :auto-size="{ minRows: 4, maxRows: 6 }"
            placeholder="填写名称"
        ></a-textarea>
        <div class="ml-10 flex-colum-start">
          <a-icon @click="handleConfirm(row)" type="check-circle" class="font-size-24 color-blue cur-pot"/>
          <a-icon @click="handleClose(row)" type="close-circle" class="font-size-24 color-red cur-pot"/>
        </div>
      </div>
      </div>
<!--   其他信息   -->
      <div slot="itemOtherInfoSlot" slot-scope="row">
        <div class="color-orange" v-if="row.goodsCompanyName">评级信息：{{ row.goodsCompanyName + ' ' + (row.goodsScoreName || '') }}</div>
        <div v-if="row.goodsExtraInfo" class="mt-10">尺寸/重量：{{ (row.goodsExtraInfo.size || '暂无') + '/' + (row.goodsExtraInfo.weight || '暂无') }}</div>
        <div v-if="row.goodsPrice" class="mt-10">成交价：<span class="color-red">{{ row.goodsPrice }}</span></div>
        <div v-if="row.tradeTime" class="mt-10">成交时间：{{ row.tradeTime }}</div>
        <div v-if="row.goodsSourceName" class="color-gray mt-10">交易平台：{{ row.goodsSourceName }}</div>
      </div>
<!--   操作   -->
      <div slot="itemAuctionSlot" slot-scope="row">
        <div style="margin-top: 5px; display: flex">
          <a-popconfirm
              title="是否确认?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleConfirmPriceQuery(row.goodsId)"
          >
            <a-button size="small" type="primary" style="margin-right: 5px">确认</a-button>
          </a-popconfirm>
          <a-button
              size="small"
              @click="editItem(row)"
              style="margin-right: 5px"
          >编辑</a-button>
          <a-popconfirm
              title="确定删除当前数据？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="remove(row.goodsId)"
          >
            <a-button
                size="small"
                type="danger"
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
import {GOODS_PAGE_TABLE_LIST} from "@/views/cmsPage/coinManage/_js/data"
import {queryPriceBatchConfirm, queryPriceBatchDel} from "@/views/cmsPage/coinManage/_apis"

export default {
  props: ["list", "loading", "pagination"],
  components: {},
  data() {
    return {
      goodsName: '',
      detail: "",
      columns: GOODS_PAGE_TABLE_LIST,
      selectedRowKeys: [],
    };
  },
  watch: {
    list() {
      this.selectedRowKeys = [];
      this.$emit("selectTable", [])
    },
  },
  methods: {
    /** 双加修改 */
    handleDoubleClick(row) {
      this.goodsName = row.goodsName
      this.$emit('doubleClick', row)
    },
    /** 确定修改 confirmClick*/
    handleConfirm(row) {
      this.$emit('confirmClick', row, this.goodsName)
    },
    /** 取消修改 closeClick*/
    handleClose(row) {
      this.$emit('closeClick', row)
    },
    /** table选中 */
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys
      this.$emit("selectTable", arr)
    },
    /** 确定确认 */
    async handleConfirmPriceQuery(id) {
      const res = await queryPriceBatchConfirm({ids: id})
      if (res.status != '200') return
      this.$message.success(res.message || "确认成功")
      this.$emit("success")
    },
    appreciationSuccess() {
      this.$emit("success")
    },
    // 编辑
    editItem(item) {
      this.$emit("editItem", item)
    },
    // 删除
    async remove(id) {
      this.$loading.show();
      const res = await queryPriceBatchDel({ids: id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(res.message || "删除成功")
      this.$emit("delSuccess", [id])
    },

    // 切换分页
    changePage(page) {
      this.$emit("changePage", page)
    },
    // 预览封面
    previewImg(row) {
      const images = row.goodsCoverList.map((el) =>  ({img_url: el}))
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  max-width: 600px;
}
</style>