var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "对版",
        width: 1200,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("div", { staticClass: "popup-content" }, [
        _c("div", { staticClass: "front-back-img-list" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("点击图片选择对版"),
            _c("span", [_vm._v("（正面与反面图）")])
          ]),
          _c(
            "div",
            { staticClass: "img-list flex-wrap" },
            _vm._l(_vm.popupData.images, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "img-item w-200 cur-pot",
                  on: {
                    click: function($event) {
                      return _vm.handleSelectedImage(index, "front")
                    }
                  }
                },
                [
                  _vm.currentIndexByFront === index
                    ? _c("div", { staticClass: "active-img-item" }, [
                        _vm._v("正面图")
                      ])
                    : _vm._e(),
                  _vm.currentIndexByBack === index
                    ? _c("div", { staticClass: "active-img-item" }, [
                        _vm._v("反面图")
                      ])
                    : _vm._e(),
                  _c("img", {
                    staticClass: "w-200",
                    attrs: { src: item, alt: "" }
                  })
                ]
              )
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }