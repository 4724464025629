<template>
  <div class="page">
    <div class="page-header">
      <div class="mb-10">
        <div class="">前台搜索条件：</div>
        <a-input
            allowClear
            v-model="apiParams.keyWord"
            size="small"
            class="mr-10 w-200"
            placeholder="关键词搜索"
        ></a-input>
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            class="mr-10 w-100"
            v-model="apiParams.sort"
            placeholder="排序搜索"
        >
          <a-select-option
              v-for="item in receptionSortTypeList"
              :value="item.value"
              :key="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-button
            @click="handleApiSearch"
            size="small"
            type="primary"
            class="ml-10"
        >前台搜索</a-button>
      </div>
      <div>
        <div class="">后台搜索条件：</div>
<!--    大币种搜索    -->
        <SearchBigOrSmallCoinByVersion
            ref="searchBigOrSmallCoinByVersionEl"
            :params="params"
            box-size="small"
            versionSearchType="queryPrice"
        >
          <template #searchParamsBefore>
            <a-input
                allowClear
                v-model="params.productName"
                size="small"
                style="width: 200px; margin-right: 10px"
                placeholder="标题 或 ID"
            ></a-input>
            <a-input
                allowClear
                v-model="params.goodsNumber"
                size="small"
                style="width: 120px; margin-right: 10px"
                placeholder="评级编号"
            ></a-input>
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                size="small"
                style="margin: 5px; width: 120px"
                v-model="params.type"
                placeholder="钱币类型"
            >
              <a-select-option :value="1">古钱币</a-select-option>
              <a-select-option :value="0">银元</a-select-option>
            </a-select>
          </template>
          <template #searchParamsAfter>
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                size="small"
                style="margin: 5px; width: 120px"
                v-model="params.priceSort"
                placeholder="价格排序"
            >
              <a-select-option :value="0">升序</a-select-option>
              <a-select-option :value="1">降序</a-select-option>
            </a-select>
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                size="small"
                style="margin: 5px; width: 120px"
                v-model="params.goodsType"
                placeholder="评级公司"
            >
              <a-select-option
                  v-for="item in ratingCompanyTypeList"
                  :value="item.value"
                  :key="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                size="small"
                style="width: 120px; margin: 5px"
                v-model="params.goodsSource"
                placeholder="数据源"
            >
              <a-select-option
                  v-for="item in productDataSourceTypeList"
                  :value="item.value"
                  :key="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>

            <a-range-picker
                :placeholder="['成交开始', '成交结束']"
                size="small"
                style="margin: 5px; width: 200px;"
                @change="searchPickerChange"
            />
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                size="small"
                style="margin: 5px; width: 100px"
                v-model="params.reviewStatus"
                placeholder="确认状态"
            >
              <a-select-option :value="0">待确认</a-select-option>
              <a-select-option :value="1">已确认</a-select-option>
            </a-select>
            <a-select
                :dropdownMatchSelectWidth="false"
                allowClear
                size="small"
                style="margin: 5px; width: 130px"
                v-model="params.duibanStatus"
                placeholder="对版状态"
            >
              <a-select-option :value="0">未对版</a-select-option>
              <a-select-option :value="1">已对版</a-select-option>
              <a-select-option :value="5">已对版-不准</a-select-option>
              <a-select-option :value="9">已对版-无结果</a-select-option>
            </a-select>
            <a-input-group compact style="width: 300px; margin: 5px">
              <a-input v-model="params.minPrice" type="number" style=" width: 100px; text-align: center;" size="small" placeholder="最低价格" />
              <a-input size="small" style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff" placeholder="~" disabled/>
              <a-input v-model="params.maxPrice" type="number" size="small" style="width: 100px; text-align: center; border-left: 0" placeholder="最高价格" />
              <a-button v-if="params.minPrice || params.maxPrice" @click="handleClearPriceRate" icon="close-circle" size="small"></a-button>
            </a-input-group>
            <a-button
                @click="search"
                type="primary"
                size="small"
                class="ml-10"
            >后台搜索</a-button>
          </template>
        </SearchBigOrSmallCoinByVersion>
      </div>
      <div class="mt-10">
        <a-button
          @click="showAdd = true"
          size="small"
        >录入</a-button>
        <a-popconfirm
            title="确定要批量确认吗？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="handleAllConfirmOrDel('confirm')"
        >
          <a-button
              class="ml-10"
              size="small"
              :disabled="selectedArrays.length <= 0"
          >批量确认</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="确定要批量删除吗？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="handleAllConfirmOrDel('del')"
        >
          <a-button
              class="ml-10"
              size="small"
              type="danger"
              :disabled="selectedArrays.length <= 0"
          >批量删除</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="page-body">
      <PageTable
         v-if="searchType === 'admin'"
         :list="list"
         :loading="loading"
         :pagination="pagination"
         @changePage="changePage"
         @editItem="editItem"
         @success="getList"
         @sameVersion="handleSameVersion($event)"
         @selectTable="handleSelectTable"
      />
      <PageTableV2
          v-if="searchType === 'reception'"
          :list="list"
          :loading="loading"
          :pagination="pagination"
          @changePage="changePage"
          @editItem="editItem"
          @success="getList"
          @selectTable="handleSelectTable"
          @doubleClick="handleDoubleClick"
          @closeClick="handleClose"
          @confirmClick="handleConfirm"
          @delSuccess="handleDelSuccess($event)"
      />
    </div>
    <TableEdit
      ref="editEl"
      @success="getList"
      @close="close"
    ></TableEdit>
    <TableAdd
      v-if="showAdd"
      @close="showAdd = false"
      @success="getList"
    ></TableAdd>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/coinManage/goods/PageTable.vue";
import TableEdit from "@/views/cmsPage/coinManage/goods/TableEdit.vue";
import TableAdd from "@/views/cmsPage/coinManage/goods/TableAdd.vue";
import {
  editorConfirmData,
  getEditorDetailData,
  getQueryPriceByAdmin,
  getQueryPriceByReception,
  queryPriceBatchConfirm,
  queryPriceBatchDel
} from "@/views/cmsPage/coinManage/_apis"
import {RECEPTION_SORT_TYPE_LIST} from "@/views/cmsPage/coinManage/_js/data"
import {PRODUCT_DATA_SOURCE_TYPE_LIST, RATING_COMPANY_TYPE_LIST} from "@/_data"
import PageTableV2 from "@/views/cmsPage/coinManage/goods/PageTableV2.vue"
import untils from "@/untils"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import SearchBigOrSmallCoinByVersion from "@/components/SearchBigOrSmallCoinByVersion/index.vue"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      // admin：后台，reception: 前台
      searchType: 'admin',
      showAdd: false,
      list: [],
      loading: false,
      editForm: true,
      selectedArrays: [],
      detail: "",
      apiParams: {
        pageNum: 1,
        pageSize: 20,
        keyWord: undefined,
        sort: 4,
      },
      // 前台排序搜索
      receptionSortTypeList: RECEPTION_SORT_TYPE_LIST,
      ratingCompanyTypeList: RATING_COMPANY_TYPE_LIST,
      // 数据来源
      productDataSourceTypeList: PRODUCT_DATA_SOURCE_TYPE_LIST,
      params: {
        pageNum: 1,
        pageSize: 20,
        sort: 0,
        productName: undefined,
        type: undefined,
        reviewStatus: undefined,
        duibanStatus: undefined,
        goodsSource: undefined,
        goodsNumber: undefined,
        priceSort: undefined,
        coinKindId: undefined,
        coinKindItemId: undefined,
        coinKindItemVersionId: undefined,
        minPrice: undefined,
        maxPrice: undefined
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
    };
  },
  components: {
    PageTable,
    PageTableV2,
    TableEdit,
    TableAdd,
    SearchBigOrSmallCoinByVersion,
  },
  mounted() {
    const { queryKeyword } = this.$route.query;
    if (queryKeyword) {
      this.searchType = 'reception'
      this.apiParams.keyWord = queryKeyword
    }
    this.getList();
  },
  methods: {
    close() {
      this.getList()
    },
    /** 前台删除------删除 */
    handleDelSuccess(ids) {
      this.list = this.list.filter(el => !ids.includes(el.goodsId))
    },
    /** 同版 */
    async handleSameVersion(data) {
      Object.assign(this.params, data)
      await this.$refs.searchBigOrSmallCoinByVersionEl.getSearchBigCoinByCoinName(data.coinKindName)
      this.search()
    },

    /** 修改标题 确定 */
    async handleConfirm(row, goodsName) {
      this.$loading.show()
      const result = await getEditorDetailData({id: row.goodsId})
      this.$loading.hide()
      if (result.status !== '200') return
      const tempData = JSON.parse(JSON.stringify(result.data))
      tempData.tempCoinKindId = tempData.coinKindId
      tempData.tempCoinKindItemId = tempData.coinKindItemId
      tempData.tempCoinKindItemVersionId = tempData.coinKindItemVersionId
      delete tempData.coinKindId
      delete tempData.coinKindItemId
      delete tempData.coinKindItemVersionId
      tempData.goodsName = goodsName
      this.$loading.show()
      const res = await editorConfirmData(tempData)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success('修改成功')
      await this.getList()
    },
    /** 关闭 */
    handleClose(row) {
      this.list.forEach((el, index) => {
        if (el.goodsId === row.goodsId) {
          this.$set(this.list[index], 'showDoubleInput',  false)
        } else {
          this.list[index].showDoubleInput = false
        }
      })
    },
    /** 双击 */
    handleDoubleClick(row) {
      this.list.forEach((el, index) => {
        if (el.goodsId === row.goodsId) {
          this.$set(this.list[index], 'showDoubleInput',  true)
        } else {
          this.list[index].showDoubleInput = false
        }
      })
    },

    /** 批量确认 */
    async handleAllConfirmOrDel(type) {
      this.$loading.show()
      let res
      const tempParams = {ids: this.selectedArrays.map(el => this.searchType === 'admin' ? el.id : el.goodsId).join(',')}
      if (type === 'confirm') {
        res = await queryPriceBatchConfirm(tempParams)
      } else {
        res = await queryPriceBatchDel(tempParams)
      }
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('已完成')
      if (this.searchType === 'admin') {
        await this.getList()
      } else {
        this.handleDelSuccess(this.selectedArrays.map(el => el.goodsId))
      }
    },
    /** 选择table */
    handleSelectTable(selectedArray) {
      this.selectedArrays = JSON.parse(JSON.stringify(selectedArray))
    },
    // 分页
    changePage(page) {
      const { current } = page
      this.$set(this.pagination, "current", current)
      if (this.searchType === 'admin') {
        this.$set(this.params, "pageNum", current)
      } else {
        this.$set(this.apiParams, 'pageNum', current)
      }
      this.getList()
    },
    /** 根据搜索类型查找列表数据 */
    async getList() {
      this.$loading.show()
      if (this.searchType === 'admin') {
        await this.getListByAdmin()
      } else {
        await this.getListByReception()
      }
    },
    /** 搜索完成列表处理 */
    getListResult(data) {
      let tempRecords, tempTotal
      if (this.searchType === 'admin') {
        data.records = data.records.map((el) => {
          const goodsExtraInfo = JSON.parse(el.goodsExtraInfo)
          return {...el, goodsExtraInfo }
        })
        const { records, total } = data
        tempRecords = records
        tempTotal = total
      } else {
        const { records, total } = data.page
        records.forEach(el => {
          el.showDoubleInput = false
        })
        tempRecords = records
        tempTotal = total
      }
      this.pagination.total = tempTotal
      this.list = tempRecords
    },
    /** 数据列表---后台 */
    async getListByAdmin() {
      const res = await getQueryPriceByAdmin(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.getListResult(res.data)
    },
    /** 数据列表---前台 */
    async getListByReception() {
      const res = await getQueryPriceByReception(this.apiParams)
      this.$loading.hide()
      if (res.status !== '200') return
      this.getListResult(res.data)
    },
    async editItem(item) {
      this.$loading.show()
      const res = await getEditorDetailData({id: this.searchType === 'admin' ?item.id : item.goodsId})
      this.$loading.hide()
      if (res.status !== '200') return
      const tempData = JSON.parse(JSON.stringify(res.data))
      tempData.tempCoinKindId = tempData.coinKindId
      tempData.tempCoinKindItemId = tempData.coinKindItemId
      tempData.tempCoinKindItemVersionId = tempData.coinKindItemVersionId
      delete tempData.coinKindId
      delete tempData.coinKindItemId
      delete tempData.coinKindItemVersionId
      this.$refs.editEl.show(tempData)
    },
    // 搜索日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.tradeStartTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.tradeEndTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      } else {
        this.params.tradeStartTime = ""
        this.params.tradeEndTime = ""
      }
    },
    /** 后台点击搜索按钮 */
    search() {
      if (this.params.minPrice) {
        if (this.params.minPrice < 0) return this.$message.warn('最小价格区间不能小于0')
        if (!this.params.maxPrice) return this.$message.warn('请填写最大价格区间')
      }
      if (this.params.maxPrice) {
        if (this.params.maxPrice <= 0) return this.$message.warn('最大价格区间不能小于等于0')
        if (this.params.minPrice) {
          if (Number(this.params.maxPrice) < Number(this.params.minPrice)) return this.$message.warn('最大价格不能小于最小价格区间')
        } else {
          return this.$message.warn('请填写最小价格区间')
        }
      }
      this.searchType = 'admin'
      this.list = []
      this.pagination.current = 1
      this.pagination.total = 0
      this.$set(this.params, "pageNum", 1)
      this.getList()
    },
    /** 前台搜索 */
    handleApiSearch() {
      if (!this.apiParams.keyWord) return this.$message.warn('请输入关键字')
      this.searchType = 'reception'
      this.list = []
      this.pagination.current = 1
      this.pagination.total = 0
      this.$set(this.apiParams, "pageNum", 1)
      this.getList()
    },
    /** 清空价格区间 */
    handleClearPriceRate() {
      this.params.minPrice = undefined
      this.params.maxPrice = undefined
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>