var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "编辑商品",
            "ok-text": "确认",
            "cancel-text": "取消",
            width: 1000
          },
          on: { cancel: _vm.cancelForm, ok: _vm.handleConfirmEditor },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.forms } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c("a-form-model-item", { attrs: { label: "封面图" } }, [
                _c(
                  "div",
                  { staticClass: "flex-start-center flex-wrap" },
                  _vm._l(_vm.forms.coverImageList, function(item, index) {
                    return _c("img", {
                      key: index,
                      staticClass: "w-100",
                      attrs: { src: item },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(_vm.forms.coverImageList, index)
                        }
                      }
                    })
                  }),
                  0
                )
              ]),
              _c("a-form-model-item", { attrs: { label: "图片展示2" } }, [
                _c(
                  "div",
                  { staticClass: "flex-start-center flex-wrap" },
                  _vm._l(_vm.forms.goodsImageList, function(item, index) {
                    return _c("img", {
                      key: index,
                      staticClass: "w-100",
                      attrs: { src: item },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(_vm.forms.goodsImageList, index)
                        }
                      }
                    })
                  }),
                  0
                )
              ]),
              _c(
                "a-form-model-item",
                { attrs: { label: "币种选择" } },
                [
                  _c("SearchBigOrSmallCoinByVersion", {
                    ref: "searchBigOrSmallCoinByVersionEl",
                    attrs: { params: _vm.forms }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标题" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "标题", rows: 4 },
                    model: {
                      value: _vm.forms.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "goodsName", $$v)
                      },
                      expression: "forms.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评级公司选择" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "mr-10 w-200",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        allowClear: "",
                        placeholder: "评级公司"
                      },
                      model: {
                        value: _vm.forms.goodsType,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "goodsType", $$v)
                        },
                        expression: "forms.goodsType"
                      }
                    },
                    _vm._l(_vm.ratingCompanyTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _c("a-input", {
                    staticClass: "w-200 ml-10",
                    attrs: { placeholder: "评级编号" },
                    model: {
                      value: _vm.forms.goodsNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "goodsNumber", $$v)
                      },
                      expression: "forms.goodsNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "交易信息" } },
                [
                  _c("a-date-picker", {
                    attrs: { placeholder: "成交时间选择" },
                    on: { change: _vm.handleSelectedPicker },
                    model: {
                      value: _vm.forms.tradeTime,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "tradeTime", $$v)
                      },
                      expression: "forms.tradeTime"
                    }
                  }),
                  _c("a-input", {
                    staticClass: "w-150 ml-20",
                    attrs: { allowClear: "", placeholder: "交易金额" },
                    model: {
                      value: _vm.forms.goodsPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "goodsPrice", $$v)
                      },
                      expression: "forms.goodsPrice"
                    }
                  }),
                  _c("span", { staticClass: "ml-5" }, [_vm._v("元")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }