import {objArrMapObj} from "@/untils/otherEvent"

/** 评级公司 1 PCGS   2 公博  3 NGC  10 保粹  11 华夏  12 众诚详评   13 至臻   14 轩德  16 闻德  18 园地评级*/
export const RATING_COMPANY_TYPE_LIST = [
    {name: '裸币', value: 20},
    {name: 'PCGS', value: 1},
    {name: '公博', value: 2},
    {name: 'NGC', value: 3},
    {name: '保粹', value: 10},
    {name: '华夏', value: 11},
    {name: '众诚详评', value: 12},
    {name: '至臻', value: 13},
    {name: '轩德', value: 14},
    {name: '闻德', value: 16},
    {name: '园地评级', value: 18},
]

/** 数据来源 */
export const PRODUCT_DATA_SOURCE_TYPE_LIST = [
    {name: 'HX', value: 1},
    {name: 'WPT', value: 3},
    {name: 'XZ', value: 4},
    {name: 'GQYD', value: 5},
    {name: 'QBTT', value: 2},
    {name: 'HS', value: 25},
]
/** wpt状态 deal 处理中
 unsold 未售出
 paid 支付成功
 delivery 传输中
 finished 交易成功*/
export const PRODUCT_WPT_STATUS_TYPE_LIST = [
    {name: '处理中', value: 'deal'},
    {name: '未售出', value: 'unsold'},
    {name: '支付成功', value: 'paid'},
    {name: '传输中', value: 'delivery'},
    {name: '交易成功', value: 'finished'},
]
export const PRODUCT_WPT_STATUS_TYPE_CLASS = {
    deal: 'color-gray',
    unsold: 'color-red',
    paid: 'color-blue',
    delivery: 'color-orange',
    finished: 'color-green',
}

/** 是否已经确认 */
export const IS_CONFIRM_STATUS_TYPE_LIST = [
    { name: '已确认', value: 1 },
    { name: '未确认', value: 0 },
]
export const IS_CONFIRM_STATUS_CLASS_TYPE = {
    0: 'color-red',
    1: 'color-green',
}

/** 快递公司映射 */
export const EXPRESS_COMPANY_CODE_MAP_TEXT = {
    yuantong: '圆通快递',
    yunda: '韵达快递',
    zhongtong: '中通快递',
    shunfeng: '顺丰快递',
    shentong: '申通快递',
    youshuwuliu: '优速物流',
    youzhengguonei: '邮政国内',
    huitongkuaidi: '百世快递',
    jd: '京东快递',
    ems: 'EMS',
    jtexpress: '极兔'
}

export const computedRatingCompanyTypeList = (value) => {
    return objArrMapObj('value', 'name', RATING_COMPANY_TYPE_LIST)[value]
}
export const computedDataSourceTypeList = (value) => {
    return objArrMapObj('value', 'name', PRODUCT_DATA_SOURCE_TYPE_LIST)[value]
}
export const computedWptStatusTypeList = (value) => {
    return objArrMapObj('value', 'name', PRODUCT_WPT_STATUS_TYPE_LIST)[value]
}
export const computedIsConfirmStatusTypeList = (value) => {
    return objArrMapObj('value', 'name', IS_CONFIRM_STATUS_TYPE_LIST)[value]
}