<template>
  <a-modal
      v-model="showPopup"
      title="对版"
      :width="1200"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <div class="front-back-img-list">
        <div class="title">点击图片选择对版<span>（正面与反面图）</span></div>
        <div class="img-list flex-wrap">
          <div
              class="img-item w-200 cur-pot"
              v-for="(item, index) in popupData.images"
              :key="index"
              @click="handleSelectedImage(index, 'front')"
          >
            <div v-if="currentIndexByFront === index" class="active-img-item">正面图</div>
            <div v-if="currentIndexByBack === index" class="active-img-item">反面图</div>
            <img class="w-200" :src="item" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {imgVersionByQueryPrice} from "@/views/cmsPage/coinManage/_apis"

export default {
  data() {
    return {
      showPopup: false,
      popupData: '',
      currentIndexByFront: 0,
      currentIndexByBack: 1,
      selectedCount: 0,
    }
  },
  methods: {
    /** 选择图片 */
    handleSelectedImage(index) {
      if (this.currentIndexByFront === index) {
        this.currentIndexByFront = -1
      } else if (this.currentIndexByBack === index) {
        this.currentIndexByBack = -1
      } else if (this.currentIndexByFront === -1) {
        this.currentIndexByFront = index
      } else if (this.currentIndexByBack === -1) {
        this.currentIndexByBack = index
      }
    },
    /** 展示弹窗 */
    show(data) {
      this.popupData = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.currentIndexByFront = 0
      this.currentIndexByBack = 1
      this.selectedCount = 0
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (this.currentIndexByBack === -1 || this.currentIndexByFront === -1) return this.$message.warning('请选择需要对版的正面图')
      this.$loading.show()
      const res = await imgVersionByQueryPrice({
        goodsId: this.popupData.id,
        frontImage: this.popupData.images[this.currentIndexByFront],
        backImage: this.popupData.images[this.currentIndexByBack],
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('versionSuccess')
      this.handleCancel()
    }
  }
}
</script>
<style scoped lang="scss">
.active-img-item {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.front-back-img-list {
  width: 100%;
  .img-list {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img-item {
      position: relative;
      width: 200px;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    color: black;
    span {
      font-weight: bold;
    }
  }
}
.popup-content {
  width: 100%;
}
</style>