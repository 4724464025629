<template>
  <div>
    <a-modal
      v-model="visible"
      title="编辑商品"
      ok-text="确认"
      cancel-text="取消"
      :width="1000"
      @cancel="cancelForm"
      @ok="handleConfirmEditor"
    >
      <a-form-model ref="ruleForm" :model="forms" v-bind="layout">
        <a-form-model-item label="封面图">
          <div class="flex-start-center flex-wrap">
            <img
                v-for="(item, index) in forms.coverImageList"
                :key="index"
                :src="item"
                class="w-100"
                @click="previewImg(forms.coverImageList, index)"
            >
          </div>
        </a-form-model-item>
        <a-form-model-item label="图片展示2">
          <div class="flex-start-center flex-wrap">
            <img
                v-for="(item, index) in forms.goodsImageList"
                :key="index"
                :src="item"
                class="w-100"
                @click="previewImg(forms.goodsImageList, index)"
            >
          </div>
        </a-form-model-item>
        <a-form-model-item label="币种选择">
<!--     大币种搜索相关     -->
        <SearchBigOrSmallCoinByVersion ref="searchBigOrSmallCoinByVersionEl" :params="forms"/>
        </a-form-model-item>
        <a-form-model-item label="标题">
          <a-textarea
              v-model="forms.goodsName"
              placeholder="标题"
              :rows="4" />
        </a-form-model-item>
<!--     评级公司   -->
        <a-form-model-item label="评级公司选择">
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              class="mr-10 w-200"
              v-model="forms.goodsType"
              placeholder="评级公司"
          >
            <a-select-option
                v-for="item in ratingCompanyTypeList"
                :value="item.value"
                :key="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              class="w-200 ml-10"
              v-model="forms.goodsNumber"
              placeholder="评级编号"
          ></a-input>
        </a-form-model-item>
<!--    交易信息    -->
        <a-form-model-item label="交易信息">
          <a-date-picker
              v-model="forms.tradeTime"
              placeholder="成交时间选择"
              @change="handleSelectedPicker"
          />
          <a-input
              allowClear
              v-model="forms.goodsPrice"
              class="w-150 ml-20"
              placeholder='交易金额'
          ></a-input>
          <span class="ml-5">元</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import SearchBigOrSmallCoinByVersion from "@/components/SearchBigOrSmallCoinByVersion/index.vue"
import { mapState } from "vuex";
import {PRODUCT_DATA_SOURCE_TYPE_LIST, RATING_COMPANY_TYPE_LIST} from "@/_data"
import {editorConfirmData} from "@/views/cmsPage/coinManage/_apis"
export default {
  components: {SearchBigOrSmallCoinByVersion},
  props: ["type"],
  data() {
    return {
      visible: false,
      forms: {
        tradeTime: undefined,
      },
      imgList: [],
      fileList: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      previewImage: "",
      // 评级公司
      ratingCompanyTypeList: RATING_COMPANY_TYPE_LIST,
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  created() {},
  methods: {
    /** 展示 */
    show(data) {
      console.log(data, '数据')
      Object.assign(this.forms, data)
      this.visible = true
      setTimeout(() => {
        if (data.tempCoinKindId && data.tempCoinKindItemId && data.tempCoinKindItemVersionId) {
          this.forms.coinKindId = data.tempCoinKindId
          this.forms.coinKindItemId = data.tempCoinKindItemId
          this.forms.coinKindItemVersionId = data.tempCoinKindItemVersionId
          this.$refs.searchBigOrSmallCoinByVersionEl.coinSearch(data.coinKindName, 'searchSmallCoin')
        }
      }, 200)
    },
    /** 确定编辑 */
    async handleConfirmEditor() {
      if (!(this.forms.coinKindId && this.forms.coinKindItemId && this.forms.coinKindItemVersionId)) {
        this.forms.coinKindId = this.forms.tempCoinKindId
        this.forms.coinKindItemId = this.forms.tempCoinKindItemId
        this.forms.coinKindItemVersionId = this.forms.tempCoinKindItemVersionId
      }
      delete this.forms.tempCoinKindId
      delete this.forms.tempCoinKindItemId
      delete this.forms.tempCoinKindItemVersionId
      this.$loading.show();
      const res = await editorConfirmData(this.forms)
      this.$loading.hide();
      if (res.status === '200') {
        this.$message.success(res.message || "编辑成功");
        this.visible = false;
        this.fileList = [];
        this.cancelForm()
        this.$emit('success')
      }
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.$previewImg({
        list: [{img_url: this.previewImage}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    cancelForm(e) {
      this.forms = {
        tradeTime: undefined
      }
    },
    /** 选择时间 */
    handleSelectedPicker(date, dateString) {
      this.forms.tradeTime= dateString
    },
    /** 图片预览 */
    previewImg(list, index) {
      const images = list.map((el) =>  ({img_url: el}));
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>