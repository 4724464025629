var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          attrs: {
            width: 1000,
            title: "编辑",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.goods, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "商品名称" },
                    model: {
                      value: _vm.goods.title,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "title", $$v)
                      },
                      expression: "goods.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评级公司", prop: "goodsType" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.goods.goodsType,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "goodsType", $$v)
                        },
                        expression: "goods.goodsType"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("公博")]),
                      _c("a-radio", { attrs: { value: 10 } }, [_vm._v("保粹")]),
                      _c("a-radio", { attrs: { value: 11 } }, [_vm._v("华夏")]),
                      _c("a-radio", { attrs: { value: 12 } }, [
                        _vm._v("众诚详评")
                      ]),
                      _c("a-radio", { attrs: { value: 20 } }, [_vm._v("裸币")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "来源", prop: "goodsSource" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "goodsSource" },
                      model: {
                        value: _vm.goods.goodsSource,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "goodsSource", $$v)
                        },
                        expression: "goods.goodsSource"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("华夏")]),
                      _c("a-radio", { attrs: { value: 2 } }, [
                        _vm._v("钱币天堂")
                      ]),
                      _c("a-radio", { attrs: { value: 3 } }, [
                        _vm._v("微拍堂")
                      ]),
                      _c("a-radio", { attrs: { value: 4 } }, [_vm._v("闲斋")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.goods.goodsType != 20,
                      expression: "goods.goodsType != 20"
                    }
                  ],
                  attrs: { label: "证书编号", prop: "certNumber" }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "证书编号" },
                    model: {
                      value: _vm.goods.pcgsNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "pcgsNumber", $$v)
                      },
                      expression: "goods.pcgsNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "币种名称", prop: "coinName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "币种名称" },
                    model: {
                      value: _vm.goods.coinName,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "coinName", $$v)
                      },
                      expression: "goods.coinName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "小币种名称", prop: "coinItemName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "小币种名称" },
                    model: {
                      value: _vm.goods.coinItemName,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "coinItemName", $$v)
                      },
                      expression: "goods.coinItemName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别名称", prop: "versionName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "版别名称" },
                    model: {
                      value: _vm.goods.versionName,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "versionName", $$v)
                      },
                      expression: "goods.versionName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评级分数", prop: "score" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "评级分数" },
                    model: {
                      value: _vm.goods.score,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "score", $$v)
                      },
                      expression: "goods.score"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "大小", prop: "size" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "大小" },
                    model: {
                      value: _vm.goods.size,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "size", $$v)
                      },
                      expression: "goods.size"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "重量", prop: "weight" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "重量" },
                    model: {
                      value: _vm.goods.weight,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "weight", $$v)
                      },
                      expression: "goods.weight"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "封面图" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.coverList
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChangeCoverList
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "金额", prop: "price" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "金额" },
                    model: {
                      value: _vm.goods.price,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "price", $$v)
                      },
                      expression: "goods.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "成交时间", prop: "tradeTime" } },
                [
                  _c("a-date-picker", {
                    attrs: { placeholder: "成交时间" },
                    on: {
                      change: function($event) {
                        return _vm.changeTime($event, 1)
                      }
                    },
                    model: {
                      value: _vm.goods.tradeTime,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "tradeTime", $$v)
                      },
                      expression: "goods.tradeTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.previewVisible,
              expression: "previewVisible"
            }
          ],
          staticClass: "preview-image"
        },
        [
          _c(
            "div",
            {
              staticClass: "preview-image-close",
              on: {
                click: function($event) {
                  _vm.previewVisible = false
                }
              }
            },
            [_vm._v(" 关闭 ")]
          ),
          _c("img", { attrs: { alt: "example", src: _vm.previewImage } })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }