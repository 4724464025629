var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          scroll: { x: 800 },
          rowKey: function(record) {
            return record.id
          },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "flex-start-center flex-wrap" },
                  _vm._l(row.goodsCovePrimaryList, function(item, index) {
                    return _c("img", {
                      key: index,
                      staticClass: "w-150",
                      attrs: { src: item + "/resize,w_250" },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(row)
                        }
                      }
                    })
                  }),
                  0
                )
              ])
            }
          },
          {
            key: "itemGoodsNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                !row.showDoubleInput
                  ? _c(
                      "div",
                      {
                        on: {
                          dblclick: function($event) {
                            return _vm.handleDoubleClick(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.goodsName))]
                    )
                  : _c(
                      "div",
                      { staticClass: "w-100-w flex-center-center" },
                      [
                        _c("a-textarea", {
                          staticClass: "w-100-w",
                          attrs: {
                            type: "text",
                            "auto-size": { minRows: 4, maxRows: 6 },
                            placeholder: "填写名称"
                          },
                          model: {
                            value: _vm.goodsName,
                            callback: function($$v) {
                              _vm.goodsName = $$v
                            },
                            expression: "goodsName"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "ml-10 flex-colum-start" },
                          [
                            _c("a-icon", {
                              staticClass: "font-size-24 color-blue cur-pot",
                              attrs: { type: "check-circle" },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(row)
                                }
                              }
                            }),
                            _c("a-icon", {
                              staticClass: "font-size-24 color-red cur-pot",
                              attrs: { type: "close-circle" },
                              on: {
                                click: function($event) {
                                  return _vm.handleClose(row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            }
          },
          {
            key: "itemOtherInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.goodsCompanyName
                  ? _c("div", { staticClass: "color-orange" }, [
                      _vm._v(
                        "评级信息：" +
                          _vm._s(
                            row.goodsCompanyName +
                              " " +
                              (row.goodsScoreName || "")
                          )
                      )
                    ])
                  : _vm._e(),
                row.goodsExtraInfo
                  ? _c("div", { staticClass: "mt-10" }, [
                      _vm._v(
                        "尺寸/重量：" +
                          _vm._s(
                            (row.goodsExtraInfo.size || "暂无") +
                              "/" +
                              (row.goodsExtraInfo.weight || "暂无")
                          )
                      )
                    ])
                  : _vm._e(),
                row.goodsPrice
                  ? _c("div", { staticClass: "mt-10" }, [
                      _vm._v("成交价："),
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v(_vm._s(row.goodsPrice))
                      ])
                    ])
                  : _vm._e(),
                row.tradeTime
                  ? _c("div", { staticClass: "mt-10" }, [
                      _vm._v("成交时间：" + _vm._s(row.tradeTime))
                    ])
                  : _vm._e(),
                row.goodsSourceName
                  ? _c("div", { staticClass: "color-gray mt-10" }, [
                      _vm._v("交易平台：" + _vm._s(row.goodsSourceName))
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemAuctionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "5px", display: "flex" } },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "是否确认?",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleConfirmPriceQuery(row.goodsId)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { size: "small", type: "primary" }
                          },
                          [_vm._v("确认")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "5px" },
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.editItem(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除当前数据？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.remove(row.goodsId)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }