var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { title: "鉴赏", "ok-text": "确认", "cancel-text": "取消" },
          on: { ok: _vm.submit },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.forms } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "ID" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", size: "small" },
                    model: {
                      value: _vm.forms.id,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "id", $$v)
                      },
                      expression: "forms.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标题" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", size: "small" },
                    model: {
                      value: _vm.forms.title,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "title", $$v)
                      },
                      expression: "forms.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "编号" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", size: "small" },
                    model: {
                      value: _vm.forms.pcgsNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "pcgsNumber", $$v)
                      },
                      expression: "forms.pcgsNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "赏析" } },
                [
                  _c("a-input", {
                    attrs: {
                      size: "small",
                      type: "textarea",
                      placeholder: "赏析内容",
                      autoSize: ""
                    },
                    model: {
                      value: _vm.forms.appreciationContext,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "appreciationContext", $$v)
                      },
                      expression: "forms.appreciationContext"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }