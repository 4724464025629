<template>
  <div>
    <a-modal
      v-drag-modal
      :width="1000"
      v-model="show"
      title="编辑"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="goods"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="商品名称">
          <a-input
            v-model="goods.title"
            placeholder="商品名称"
          />
        </a-form-model-item>
        <a-form-model-item
          label="评级公司"
          prop="goodsType"
        >
          <a-radio-group
            name="radioGroup"
            v-model="goods.goodsType"
          >
            <a-radio :value="2">公博</a-radio>
            <a-radio :value="10">保粹</a-radio>
            <a-radio :value="11">华夏</a-radio>
            <a-radio :value="12">众诚详评</a-radio>
            <a-radio :value="20">裸币</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="来源"
          prop="goodsSource"
        >
          <a-radio-group
            name="goodsSource"
            v-model="goods.goodsSource"
          >
            <a-radio :value="1">华夏</a-radio>
            <a-radio :value="2">钱币天堂</a-radio>
            <a-radio :value="3">微拍堂</a-radio>
            <a-radio :value="4">闲斋</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="证书编号"
          prop="certNumber"
          v-show="goods.goodsType != 20"
        >
          <a-input
            placeholder="证书编号"
            v-model="goods.pcgsNumber"
          />
        </a-form-model-item>
        <a-form-model-item
          label="币种名称"
          prop="coinName"
        >
          <a-input
            placeholder="币种名称"
            v-model="goods.coinName"
          />
        </a-form-model-item>
        <a-form-model-item
          label="小币种名称"
          prop="coinItemName"
        >
          <a-input
            placeholder="小币种名称"
            v-model="goods.coinItemName"
          />
        </a-form-model-item>
        <a-form-model-item
          label="版别名称"
          prop="versionName"
        >
          <a-input
            placeholder="版别名称"
            v-model="goods.versionName"
          />
        </a-form-model-item>

        <a-form-model-item
          label="评级分数"
          prop="score"
        >
          <a-input
            placeholder="评级分数"
            v-model="goods.score"
          />
        </a-form-model-item>
        <a-form-model-item
          label="大小"
          prop="size"
        >
          <a-input
            placeholder="大小"
            v-model="goods.size"
          />
        </a-form-model-item>
        <a-form-model-item
          label="重量"
          prop="weight"
        >
          <a-input
            placeholder="重量"
            v-model="goods.weight"
          />
        </a-form-model-item>
        <a-form-item label="封面图">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="coverList"
            @preview="handlePreview"
            @change="handleChangeCoverList"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-model-item
          label="金额"
          prop="price"
        >
          <a-input
            placeholder="金额"
            v-model="goods.price"
          />
        </a-form-model-item>
        <a-form-model-item
          label="成交时间"
          prop="tradeTime"
        >
          <a-date-picker
            placeholder="成交时间"
            @change="changeTime($event, 1)"
            v-model="goods.tradeTime"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <div
      class="preview-image"
      v-show="previewVisible"
    >
      <div
        class="preview-image-close"
        @click="previewVisible = false"
      >
        关闭
      </div>
      <img
        alt="example"
        :src="previewImage"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      show: true,
      goods: {},
      previewVisible: false,
      previewImage: "",
      coverList: [],
      checkVersionsList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {
      },
    };
  },
  computed: {
    ...mapState(["ip"]),

    showCheckRadio() {
      let inner;
      if (this.checkVersionsList.length > 1) {
        inner = true;
      } else {
        inner = false;
      }
      return inner;
    },
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
  },
  methods: {
    changeTime(time) {
      const { _d } = time;
      this.goods.tradeTime = new Date(_d)
        .toLocaleDateString()
        .replace(/\//g, "-");
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    previewImgs() {
      const picturesList = this.fileList.map((row) => {
        return row.url;
      });
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    handlePreview(file, type) {
      this.handlePreviewProxy(file);
    },
    async handlePreviewProxy(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    hideModal(type) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.goods);
        } else {
          return false;
        }
      });
    },
    async submit(forms) {
      const abs = this.coverList.map((row) => {
        return row.url;
      });
      forms.coverUrl = abs.join(",");
      const sendObj = {
        id: forms.id,
        title: forms.title,
        coinName: forms.coinName,
        coinItemName: forms.coinItemName,
        versionName: forms.versionName,
        score: forms.score,
        tradeTime: forms.tradeTime,
        price: forms.price,
        certNumber: forms.certNumber,
        coverUrl: forms.coverUrl,
        goodsType: forms.goodsType,
        pcgsNumber: forms.pcgsNumber,
        goodsSource: forms.goodsSource,
      };
      const res = await this.axios.post(
        "/dq_admin/coinSilverVersionGoods/inputCoinVersionGoods",
        sendObj
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.$emit("success");
      this.resetForms()
    },
    resetForms() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>
